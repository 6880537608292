import { React, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useShopify } from '../hooks';
import Product from './Product';
import Navbar from './Navbar';
import Footer from './Footer';
import Cart from './Cart';
import { fabClasses } from '@mui/material';
import glasses from '../images/glasses.png'

const Placeholder = () => {
	const getNumberOfItems = () => {
		const width = window.innerWidth;

		if (width < 960) return 4;
		if (width < 1280) return 6;
		return 8;
	};

	const numberedArray = Array.from(Array(getNumberOfItems()).keys());

	return numberedArray.map((number) => (
		<Grid item xs={6} md={4} lg={3} key={number}>
			<div className="loading_block" />
		</Grid>
	));
};






const Home = (props) => {

	const { products } = useShopify();
	const [shirtsEnabled, setShirtsEnabled] = useState(false);
	const [hoodiesEnabled, setHoodiesEnabled] = useState(false);
	const [miscEnabled, setMiscEnabled] = useState(false);
	const [productsEnabled, setproductsEnabled] = useState(true)
	const [latestEnabled, setLatestEnabled] = useState(false)


	const displayAll = () => {
		setproductsEnabled(true);
		setShirtsEnabled(false);
		setHoodiesEnabled(false);
		setMiscEnabled(false);
		setLatestEnabled(false)
	}

	const displayShirts = () => {
		setShirtsEnabled(true);
		setHoodiesEnabled(false);
		setMiscEnabled(false);
		setproductsEnabled(false);
		setLatestEnabled(false)
	}

	const displayHoodies = () => {
		setHoodiesEnabled(true);
		setShirtsEnabled(false);
		setMiscEnabled(false)
		setproductsEnabled(false);
		setLatestEnabled(false)
	}

	const displayMisc = () => {
		setMiscEnabled(true);
		setHoodiesEnabled(false);
		setShirtsEnabled(false);
		setproductsEnabled(false);
		setLatestEnabled(false)
	}

	const displayLatest = () => {
		setLatestEnabled(true);
		setMiscEnabled(false);
		setHoodiesEnabled(false);
		setShirtsEnabled(false);
		setproductsEnabled(false);
	}

	return (
		<>


			<div>
				<div className="floating" onClick={displayAll}>
					<img src="././Assets/logo.jpg" width="60" height="60" alt="Bigbst4tz2 Merch Store" />
				</div>
				<div className="App__header">

					<div className="carousel-image">
						<img src="././Assets/banner.png" alt="Bigbst4tz2 Merch Store" />
					</div>

				</div>

				<div className="App__title">
					<h1 className="App__title-text">The Official Bigbst4tz2 Clothing Store</h1>
				</div>
			</div>
			<div className="Products-wrapper">
				<Grid container spacing={2}>
					<Grid item xs={false} md={1} xl={2} />
					<Grid item xs={12} md={10} xl={8}>
						<div className="Products-wrapper__inner">
							<Grid container spacing={3}>
								{products && products.length > 0 ? (
									products.map((product, i) => (
										<Grid item xs={6} md={4} lg={3} key={i}>
											<Product product={product} history={props.history} />
										</Grid>
									))
								) : (
									<Placeholder />
								)}
							</Grid>
						</div>
						<Grid item xs={false} md={2} />
					</Grid>
				</Grid>
			</div >
			<Cart />
			<Footer />
		</>
	);
};


export default Home;