import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';

const Navbar = () => {

  return (
    <AppBar position="fixed" style={{ background: 'none', boxShadow: 'none', height: '80px' }}>
      <StyledToolbar style={{ background: 'none' }}>
        <StyledIcon src="/././Assets/logo.png" width="50" height="60" alt="icon" className="Shop__icon" />
      </StyledToolbar>
    </AppBar>
  );
};

const StyledToolbar = styled(Toolbar)`
  display: flex;
  height: 60px;

  @media (min-width: 600px) {
    height: 70px;
  }

  @media (min-width: 1080px) {
    height: 80px;
  }
`;

const StyledIcon = styled.img`
  height: 40px;
  width: auto;

  @media (min-width: 600px) {
    height: 50px;
  }

  @media (min-width: 1080px) {
    height: 60px;
  }
`;

export default Navbar;

